import { DamageVisibility } from '@/components/damage-panel/DamagePanel';
import { Component, Vue, Model } from 'vue-property-decorator';
import { Report as ReportModel } from '@/models/Report';
import { Damage } from '@/models/DamageNew';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';

@Component<Schades>({})
export default class Schades extends Vue {
  public $pageTitle = 'Schades';

  @Model('report', { default: () => new ReportModel() })
  protected report!: ReportModel;

  protected isLoading = true;

  protected damages: Damage[] = [];

  protected damageVisibility: DamageVisibility = {
    identicals: true,
    media: true,
    repairs: ! this.report.isVesNulmetingReport,
    ambtshalve: true,
    anomalies: true,
  };

  protected async mounted() {
    this.isLoading = true;
    await this.loadDamages();

    this.isLoading = false;
  }

  protected async loadDamages() {
    this.damages = await new Damage()
      .include('anomalies')
      .refactor()
      .filter({
        report: this.report.uuid,
      })
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }
}
