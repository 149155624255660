var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.report
    ? _c(
        "div",
        { staticClass: "elementPanel" },
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _vm.isLoading
                    ? _c("LoaderCard", {
                        attrs: {
                          flat: "",
                          type: "spinner--center",
                          minHeight: "300px"
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          !_vm.isLoading
            ? [
                _vm.damages
                  ? _c("DamagePanel", {
                      ref: "damagePanel",
                      attrs: {
                        damages: _vm.damages,
                        includes: [
                          "overrule_reasons",
                          "reject_reasons",
                          "media",
                          "repairs",
                          "anomalies"
                        ],
                        visibility: _vm.damageVisibility,
                        isVES: _vm.report.isVesNulmetingReport
                      }
                    })
                  : _vm._e()
              ]
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }